import React from "react"
import { SEO as Seo } from '../../components/SEO.js'
import ByaherosContent from "../../components/ByaherosContent"
import ByaherosHeader from "../../components/ByaherosHeader"
import Layout from "../../components/Layout"

const Byaheros = () => {
  return (
    <Layout>
      <Seo 
        title="Blackfort - Byaheros"
        description="We take great pride with our successful projects with these top tier companies: from finance, to logistics, local cooperative, to air travel support. With talented teams full of software developing experts, we put customers' satisfaction with the 1st priority."
        url="https://www.blackfort.ph/projects/byaheros"
      />
      <ByaherosHeader />
      <ByaherosContent />
    </Layout>
  )
}

export default Byaheros

import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import styled from "styled-components"
import mbe from "./multimedia/works-2.jpg"
import cebupacific from "./multimedia/works-3.jpg"
import techstack1 from "./multimedia/techstack-1.png"
import techstack2 from "./multimedia/techstack-2.png"
import techstack3 from "./multimedia/techstack-3.png"
import { Link } from "gatsby"

const ByaherosContent = () => {
  return (
    <StyledContainer fluid>
      <Content>
        {/* CHALLENGE SECTION */}
        <StyledRow reversed>
          <StyledCol
            lg={6}
            alignRight
            bigPadding
            data-aos="fade-right"
            data-aos-delay="700"
            data-aos-offset="300"
          >
            <Title isBold>Challenge</Title>
            <TitleDesc>e-Commerce Solution</TitleDesc>
            <TitleDesc>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </TitleDesc>
          </StyledCol>
          <StyledCol
            lg={6}
            bigPadding
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="400"
          >
            <Image src={mbe} alt="MBE" />
          </StyledCol>
        </StyledRow>

        {/* OUR SOLUTIONS SECTION */}
        <StyledRow lineBreak>
          <StyledCol
            lg={6}
            bigPadding
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="400"
          >
            <Image src={cebupacific} alt="Cebu Pacific" />
          </StyledCol>
          <StyledCol
            lg={6}
            bigPadding
            data-aos="fade-left"
            data-aos-delay="700"
            data-aos-offset="300"
          >
            <Title isBold>Our Solution</Title>
            <TitleDesc>e-Commerce Solution</TitleDesc>
            <TitleDesc>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </TitleDesc>
          </StyledCol>
        </StyledRow>

        <StyledRow lineBreak>
          <StyledCol lg={12} isCenter>
            <Title isBold>Technology Stack</Title>
          </StyledCol>
          <StyledCol lg={12} isRow isCenter>
            <StyledCol lg={4} md={4} xs={12}>
              <Image src={techstack1} alt="React Native" />
            </StyledCol>
            <StyledCol lg={4} md={4} xs={12}>
              <Image src={techstack2} alt="Angular" />
            </StyledCol>
            <StyledCol lg={4} md={4} xs={12}>
              <Image src={techstack3} alt="React" />
            </StyledCol>
          </StyledCol>
        </StyledRow>

        <StyledRow lineBreak>
          <StyledCol lg={12}>
            <Image src={mbe} alt="MBE" style={{ maxHeight: "450px" }} />
          </StyledCol>
          <StyledCol lg={6}>
            <Image src={cebupacific} alt="Cebu Pacific" />
          </StyledCol>
          <StyledCol lg={6}>
            <Image src={cebupacific} alt="Cebu Pacific" />
          </StyledCol>
        </StyledRow>

        <StyledRow>
          <StyledCol lg={12} isCenter>
            <Title isBold>View More Project</Title>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol lg={3}>
            <Link to="/projects">
              <Image src={cebupacific} alt="Cebu Pacific" />
            </Link>
          </StyledCol>
          <StyledCol lg={3}>
            <Link to="/projects">
              <Image src={cebupacific} alt="Cebu Pacific" />
            </Link>
          </StyledCol>
          <StyledCol lg={3}>
            <Link to="/projects">
              <Image src={cebupacific} alt="Cebu Pacific" />
            </Link>
          </StyledCol>
          <StyledCol lg={3}>
            <Link to="/projects">
              <Image src={cebupacific} alt="Cebu Pacific" />
            </Link>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol isCenter>
            <Link to="/projects">Back to Project</Link>
          </StyledCol>
        </StyledRow>
      </Content>
    </StyledContainer>
  )
}

export default ByaherosContent

const StyledContainer = styled(Container)`
  padding: 10vh 0 0 0;
`

const Content = styled.div`
  padding: 0 10% 0 10%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 5vw 15vh 5vw;
  }
`

const StyledRow = styled(Row)`
  display: flex;
  padding-bottom: 10vh;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ lineBreak }) => (lineBreak ? "10vh" : "0")};

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: ${({ isCenter }) => (isCenter ? "center" : "none")};
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
  padding: ${({ bigPadding }) => (bigPadding ? "2vw" : "0.5vw")};
  text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};

  @media screen and (max-width: 768px) {
    text-align: center;
    flex-direction: column;
  }

  img {
    width: 100%;
  }
`

const TitleDesc = styled.p`
  @media screen and (max-width: 768px) {
    text-align: justify;
    text-justify: auto;
  }
`

const Title = styled.h1`
  font-weight: ${({ isBold }) => (isBold ? "900" : "400")};
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`
